import { useEffect, useState } from "react";

export default function useLightbox<T>(
  items: T[],
  createUrl: (T) => string
): [T, (shown: T) => void, (offset: number) => void] {
  const [shownItem, setShownItem] = useState<T | null>(null);

  const setShownItemAndUpdateUrl = (item: T) => {
    if (item !== shownItem) {
      setShownItem(item);
      if (item) {
        const url = createUrl(item);
        history.replaceState(null, "", url);
      } else {
        history.replaceState(null, "", "#");
      }
    }
  };

  const navigate = (offset: number) => {
    let index = (items.indexOf(shownItem) + offset) % items.length;
    while (index < 0) {
      index += items.length;
    }
    setShownItemAndUpdateUrl(items[index]);
  };

  useEffect(() => {
    const toShow = items.filter((i) => createUrl(i) == window.location.hash)[0];
    if (window.location.hash && toShow) {
      setShownItem(toShow);
    } else {
      setShownItem(null);
    }
  }, [createUrl, items]);

  return [shownItem, setShownItemAndUpdateUrl, navigate];
}
